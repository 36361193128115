import fetchClient from '@kathondvla/sri-client/fetch-sri-client';
import settings from '../../config/settings';

export const sriClient = fetchClient({
  baseUrl: settings.apisAndUrls.api,
});

export const searchClient = fetchClient({
  baseUrl: settings.apisAndUrls.api,
});

export const securityClient = fetchClient({
  baseUrl: settings.apisAndUrls.security,
});

export const contentApi = fetchClient({
  baseUrl: settings.apisAndUrls.contentApi,
});

export const cachedContentApi = fetchClient({
  baseUrl: settings.apisAndUrls.cachedContentApi,
});

export const websitesApi = fetchClient({
  baseUrl: settings.apisAndUrls.websitesApi,
});
